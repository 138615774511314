// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCOWfd2wSHMH0rcgTWc5Sc-6LpD7Zdl0EM",
  authDomain: "vexclusivo.firebaseapp.com",
  projectId: "vexclusivo",
  storageBucket: "vexclusivo.appspot.com",
  messagingSenderId: "405703470238",
  appId: "1:405703470238:web:8f971a7b18056e2d6d9f8f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

//const apiUrl = 'https://us-central1-venezuela-exchange-527e6.cloudfunctions.net/getlandingdata';
export const getRates = async () => {
  const tasasRef = doc(db, "Global", "Tasas");
  const divisasRef = doc(db, "Global", "Tasas-Divisas");
  const tasasSnap = await getDoc(tasasRef);
  const divisasSnap = await getDoc(divisasRef);

  if (tasasSnap.exists() && divisasSnap.exists()) {
    return { ...tasasSnap.data(), divisasData: divisasSnap.data() }
  } else {
    // docSnap.data() will be undefined in this case
    // eslint-disable-next-line no-console
    console.log("No such document!");
  }

  /* const option = {
    method: "GET",
  };
  const response = await fetch(apiUrl, option);
  return await response.json(); */
}