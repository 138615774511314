<template>
  <div class="container px-0">
    <Banner></Banner>
    <YoutubeVideo></YoutubeVideo>
    <!-- <Partner></Partner> -->
    <Solutions></Solutions>
    <Separator></Separator>
    <PaymentMethods></PaymentMethods>
    <Mission></Mission>
    <WhyChooseUs></WhyChooseUs>
    <History></History>
    <About></About>
    <!-- <Projects></Projects> -->
    <!-- <Pricing></Pricing> -->
    <Feedback></Feedback>
    <FAQ></FAQ>
    <!-- <Blog></Blog> -->
    <!-- <Trial></Trial> -->
    <!-- <WarningModal></WarningModal> -->
  </div>
</template>

<script>
import Banner from "./landing-sections/Banner";
// import Partner from "./landing-sections/Partner";
import YoutubeVideo from "./landing-sections/YoutubeVideo";
import Solutions from "./landing-sections/Solutions";
import PaymentMethods from "./landing-sections/PaymentMethods";
import Mission from "./landing-sections/Mission";
import About from "./landing-sections/About";
import WhyChooseUs from "./landing-sections/Why-choose-us";
import History from "./landing-sections/OurHistory";
// import Projects from "./landing-sections/Projects";
// import Pricing from "./landing-sections/Pricing";
import Feedback from "./landing-sections/Feedback";
import FAQ from "./landing-sections/Faq";
import Separator from "./landing-sections/Separator";
// import Blog from "./landing-sections/Blog";
// import Trial from "./landing-sections/Trial";
// import WarningModal from "../other-pages/common/WarningModal";

export default {
  name: "LandingPage",
  components: {
    Banner,
    // Partner,
    YoutubeVideo,
    Solutions,
    PaymentMethods,
    Mission,
    About,
    WhyChooseUs,
    History,
    // Projects,
    // Pricing,
    Feedback,
    FAQ,
    // Blog,
    // Trial,
    // WarningModal,
    Separator,
  },
};
</script>

<style>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  text-align: justify;
  text-justify: auto;
}
body {
  overflow-x: hidden !important;
}
.container {
  max-width: 100% !important;
  overflow-x: hidden !important;
}
.nav-tabs .nav-item .nav-link.active {
  background-color: #30735a;
  color: #fff;
  border: 1px solid #30735a;
  border-radius: 10px 10px 0 0;
}
.nav-tabs .nav-item .nav-link {
  background-color: #fff;
  color: #30735a;
  border-radius: 10px 10px 0 0;
}
</style>