var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-title mb-0"},[_c('h2',[_vm._v(_vm._s(_vm.$t("feedback.title")))]),_c('div',{staticClass:"bar"})]),_c('div',{staticClass:"row justify-content-around align-items-center"},[_c('div',{staticClass:"col-sm-12 col-lg-8"},[_c('b-carousel',{attrs:{"id":"carousel-instagram-comments","interval":5000,"controls":"","img-width":"1024","img-height":_vm.imgHeight},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.commentsData),function(comment){return _c('b-carousel-slide',{key:comment.hashtag,attrs:{"img-blank":"","img-alt":"Carousel Background","indicators":false}},[_c('div',{class:[
                _vm.width >= 575
                  ? 'row justify-content-around align-items-center'
                  : 'row justify-content-around align-items-center comment-container-responsive',
              ]},[_c('div',{staticClass:"col-sm-4"},[_c('img',{class:[
                    _vm.width >= 1068
                      ? 'profile-photo'
                      : 'profile-photo-responsive',
                  ],attrs:{"src":require(`../../../assets/img/feedback/profile-pictures/${comment.hashtag}.png`),"alt":`${comment.hashtag} instagram profile picture`}})]),_c('div',{staticClass:"col-sm-8"},[_c('p',{class:[_vm.width <= 575 ? 'text-responsive' : '']},[_c('strong',[_vm._v(_vm._s(comment.hashtag))])]),_c('p',{class:[
                    _vm.width <= 765 ? 'text-responsive text-info' : 'text-info',
                  ]},[_vm._v(" "+_vm._s(comment.text)+" ")])])])])}),1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }