import Vue from 'vue';
import VueRouter from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue';
import VueFeather from 'vue-feather';
import vWow from 'v-wow';
import VueCarousel from 'vue-carousel';
import Toasted from 'vue-toasted';
import App from './App.vue'
import './assets/style/custom.scss';
import { router } from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import FlagIcon from 'vue-flag-icon';
import linkify from 'vue-linkify';
import Vuelidate from 'vuelidate';
import VueYoutube from 'vue-youtube'
import VueScrollTo from 'vue-scrollto'
import en from './locales/en.json';
import es from './locales/es.json';

Vue.use(FlagIcon);
Vue.use(VueI18n);
Vue.use(Vuelidate);
Vue.use(VueYoutube)
Vue.config.productionTip = false;

const messages = { en, es };
const locale = es /* getCurrentLanguage() */;
const i18n = new VueI18n({
  locale, // set locale
  fallbackLocale: 'es', // set fallback locale
  messages, // set locale messages
});

Vue.use(BootstrapVue);
Vue.use(VueFeather);
Vue.use(VueRouter);
Vue.use(vWow);
Vue.use(VueCarousel);
Vue.use(Toasted);
Vue.directive('linkified', linkify)
Vue.use(VueScrollTo)

export default new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
